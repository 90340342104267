@tailwind base;
@tailwind components;
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-quartz.css';

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-[999999999999];
}

@tailwind utilities;

@font-face {
  font-family: 'komica-axis';
  src: url('/fonts/komica-axis.woff');
}

@font-face {
  font-family: 'press-start';
  src: url('/fonts/press-start-2p.woff');
}

@layer base {
  html {
    @apply h-full bg-black text-white;
  }
  #__next {
    @apply h-full;
  }
  body {
    @apply h-full;
  }
}

@layer utilities {
  .prose :where(iframe):not(:where([class~='not-prose'] *)) {
    @apply w-full h-auto aspect-video;
  }
}

.selecting {
  color: black !important;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');

input[type='email']:disabled {
  @apply bg-transparent;
}

input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 3px solid #008000;
  cursor: pointer;
  margin-top: -8px;
}
/* Hide the default calendar icon for Webkit browsers */
input[type='date']::-webkit-calendar-picker-indicator {
  background-color: white;
  mix-blend-mode: difference;
  color: red !important;
  cursor: pointer;
  font-size: 24px;
  border-radius: 5px;
}

/* You can add similar CSS for other browsers if needed */

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #008000;
  border-radius: 10px;
}

.mouse-pointer {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
}

.mouse-pointer:hover {
  background: rgba(255, 255, 255, 0.2);
  transition-duration: 300ms;
}

.green-mouse-pointer {
  cursor: pointer;
  @apply bg-[#008000];
}

.green-mouse-pointer:hover {
  @apply bg-[#008000]/80;
  transition-duration: 300ms;
}

.active-mouse-pointer {
  cursor: pointer;
  @apply bg-[#F7941D];
}

.active-mouse-pointer:hover {
  @apply bg-[#F7941D]/80;
  transition-duration: 300ms;
}

.hero-backgroud {
  background-image: radial-gradient(at 10% 3%, #0080002f 0px, transparent 20%),
    radial-gradient(at 47% 990px, #0080002f 0px, transparent 20%),
    radial-gradient(at 100% 1660px, #0080002f 0px, transparent 20%),
    radial-gradient(at 5% 1660px, #0080002f 0px, transparent 20%),
    linear-gradient(180deg, #01050d 0%, transparent 100%);
}

*:focus {
  outline: none;
}

.full-width-container {
  width: calc(100vw - 150px);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

* {
  font-family: 'Inter', sans-serif;
  font-display: swap;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff;
  transition: 5000s ease-in-out 0s;
  border: none;
}

.login-form input:-webkit-autofill,
.login-form input:-webkit-autofill:hover,
.login-form input:-webkit-autofill:focus,
.login-form input:-webkit-autofill:active,
.login-form textarea:-webkit-autofill,
.login-form textarea:-webkit-autofill:hover,
.login-form textarea:-webkit-autofill:focus,
.login-form select:-webkit-autofill,
.login-form select:-webkit-autofill:hover,
.login-form select:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff;
  transition: 5000000000000000s ease-in-out 0s;
  border: none;
}

.clippath {
  clip-path: polygon(
    90% 0,
    100% 21%,
    100% 60%,
    100% 100%,
    10% 100%,
    0 76%,
    0 0
  );
}

.backdrop {
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

.radial-gradiant {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.back-leader {
  background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0) 15.49%,
      rgba(0, 0, 0, 0.402174) 19.89%,
      rgba(0, 0, 0, 0.770833) 111.11%,
      #000000 100%
    ),
    url('/img/wallpaperflare.webp');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.radio-button [type='radio']:checked {
  background-image: url('../../public/img/right.webp');
  width: 15px !important;
  height: 15px !important;
  padding: 9px !important;
  background-size: 50% 50% !important;
}
.radio-button1 [type='radio'] {
  border-radius: 50%;
}

button:hover {
  opacity: 0.87;
}

.table-single-prediction {
  width: calc(100vw - 5rem - 64px);
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

/* @media screen and (min-width: 327) and (max-width: 423px) {
  .help-contain {
    width: 75vw !important;
  }
}

@media screen and (max-width: 327px) {
  .help-contain {
    width: 90vw !important;
  }
} */

/* .g-recaptcha {
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
} */

#container_plinko > canvas {
  background-color: transparent !important;
}

.lilbit-extraClass {
  background-color: rgb(255 255 255 / 0.1);
  padding-left: 5px;
}

.ag-body {
  overflow: auto !important;
}

.ag-root ::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

.ag-theme-quartz-dark .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: none !important;
}

.scroller {
  overflow-y: scroll;
  scrollbar-color: #0c3413 transparent !important;
  scrollbar-width: thick;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.scroller::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

::-webkit-scrollbar-track-piece {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #0c3413;
  border-radius: 20px;
}

.ag-theme-quartz-dark .ag-root-wrapper {
  border: none;
}
/* .ag-body-horizontal-scroll {
  margin-bottom: 5px !important;
} */

.ag-body-horizontal-scroll {
  cursor: pointer;
}

.ag-horizontal-left-spacer.ag-scroller-corner {
  overflow-x: scroll;
}

.ag-horizontal-right-spacer.ag-scroller-corner {
  overflow-x: scroll;
}
.ag-body-horizontal-scroll-viewport {
  overflow: scroll;
}

.ag-header-container {
  background-color: transparent;
}
.ag-header-viewport {
  background-color: transparent;
}

.ag-row {
  background-color: transparent !important;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-root-wrapper {
  border-color: rgb(000 000 000 / 0.1) !important;
  border-radius: 0.5rem !important;
  background: transparent !important;
}

.ag-body-horizontal-scroll-viewport {
  overflow-y: hidden !important;
}

.close-icon-right:hover path {
  stroke: #f7941d;
}

.my-custom-grid .ag-header-cell {
  color: white !important;
}

.my-custom-grid .ag-header-cell-label {
  padding: 10px; /* Adjust this value to increase/decrease the padding */
}

.my-custom-grid .ag-header-cell-text {
  color: white !important;
}

.ag-theme-quartz-dark .ag-row {
  border: none !important;
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-theme-quartz-dark .ag-header-cell-text {
  font-size: 14px !important;
  font-weight: 600;
}

.ag-header-cell.text-center .ag-header-cell-label {
  justify-content: left;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.custom-shadow {
  text-shadow: 0px 2px 2px rgba(2, 1, 1, 1);
}

.play-btn-shadow {
  text-shadow: 2px 0px 3px rgba(2, 1, 1, 1);
  text-stroke: 1.5px black;
  -webkit-text-stroke: 1.5px black;
}

.nc-footer {
  display: none !important;
}

@media screen and (max-width: 450px) {
  .css-facrcq {
    width: 350px !important;
    margin-left: 8px !important;
  }
  .ag-picker-field > .ag-label {
    display: none !important;
  }
  .ag-paging-row-summary-panel {
    font-size: 12px !important;
  }
  .ag-paging-description {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 350px) {
  .css-facrcq {
    width: 300px !important;
    margin-left: 6px !important;
  }
}

@media screen and (max-width: 280px) {
  .css-facrcq {
    width: 275px !important;
    margin-left: 2px !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.privacy-page h3 {
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
  padding: 32px 0px 0px;
}

.privacy-page p {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  padding: 24px 0px 0px;
}

/* BlackJack */
@media (max-width: 1023px) {
  #logo {
    width: 90%;
  }
}
#logo {
  width: 45%;
}
@media (max-width: 1023px) {
  #orbits {
    width: 40%;
  }
}
#orbits {
  width: 20%;
  opacity: 0.25;
  margin: 2rem;
}

.menu-button {
  display: none !important;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 6rem;
  height: 6rem;
  min-width: 48px;
  min-height: 48px;
  z-index: 100;
  background: transparent;
  border: 0;
  opacity: 0.5;
  cursor: pointer;
}
.menu-button svg {
  width: 66.6%;
  height: 66.6%;
  fill: rgb(239, 240, 252);
}
@media (max-width: 1199px) {
  .menu-button {
    display: flex !important;
  }
}
.menu-button:hover {
  opacity: 0.75;
}
.line {
  height: 1px;
  background: rgb(239, 240, 252);
  width: 24px;
}
.game {
  display: flex;
  flex-flow: row nowrap;
}

.game-area {
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 50;
}
.no-pointer-events {
  pointer-events: none;
}
.dealer-side {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  min-height: 12.4rem;
}
.player-side {
  flex: 1 0;
  display: flex;
  flex-flow: column nowrap;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}
.stars-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.stars {
  position: relative;
  width: 100%;
  height: 100%;
}
.stars svg {
  left: -80px;
  animation: slide-right 5s linear infinite;
}
@keyframes slide-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(100vw + 80px));
  }
}

.game-over {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.controls-row {
  text-align: center;
}
.controls {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.bank {
  background: rgba(0, 0, 0, 0.1);
  height: 4rem;
  display: inline-flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
  font-weight: 500;
  font-size: 3rem;
  transition: all 0.2s ease;
  color: #e1ae0f;
}
.bank small {
  font-size: 2rem;
  font-weight: 300;
  margin: 0.5rem;
}
.bank.is-increasing {
  background: rgba(0, 0, 0, 0.2);
}
.bank .number {
  transition: all 0.2s ease;
}
.bank.is-increasing .number {
  transform: scale(1.1);
}
.is-spinning {
  animation: spinner 1s ease;
  transform-origin: center;
  transform-box: fill-box;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
@keyframes spinner {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(2160deg);
  }
}

.game-hand {
  position: relative;
  transition: transform 0.2s ease;
}
.game-hand.is-dealer,
.game-hand.is-split {
  transform: scale(0.9);
}
.game-hand.is-active,
.game-hand.is-split.is-active {
  transform: scale(1.2);
  z-index: 100;
}
.game-hand.is-split {
  transition: scale 0s;
}
.game-hand.is-inactive {
  opacity: 0.3;
}
.deal-enter-active {
  animation: deal 0.3s;
}
.deal-leave-active {
  animation: deal 0.3s reverse;
}
.is-split .deal-leave-active {
  animation-duration: 0;
}
@keyframes deal {
  0% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(0);
  }
}

/* svg {
  fill: currentColor;
  color: rgb(12, 36, 48);
} */

.game-button {
  display: inline-block;
  padding: 0 0.5rem;
  max-width: 8rem;
  height: 6rem;
  border: 0;
  margin: 0 0.5rem;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 2em;
  transition: opacity 0.2s ease;
  box-shadow: 0 0.25rem 0 0 rgba(0, 0, 0, 0.25);
  transform: translateY(-0.125rem);
  cursor: pointer;
}
.game-button svg {
  width: 100%;
  height: 100%;
}
.game-button.is-disabled {
  cursor: default;
  opacity: 0.25;
}
.game-button.is-disabled svg {
  opacity: 0.5;
}
.game-button .dot {
  display: none;
}
.game-button.is-highlighted:not(.is-disabled) .dot {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #e1ae0f;
}
.game-button:not(.is-disabled):not(:active):hover {
  background-color: rgb(239, 240, 252);
  transform: translateY(-0.25rem);
  box-shadow: 0 0.375rem 0 0 rgba(0, 0, 0, 0.25);
}
.game-button:not(.is-disabled):active {
  box-shadow: 0 0 0;
  transform: translateY(0);
}
.card {
  position: relative;
  display: inline-block;
  margin: 0.2rem;
  transition: all 0.2s ease;
}
.card .front,
.card .back {
  border-radius: 0.75rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}
.card-animation {
  will-change: transform, opacity;
}
.card .front {
  background-image: url('/svgs/card-front.svg');
  background-color: rgb(12, 36, 48);
}
.card .back {
  background-image: url('/svgs/card-back.svg');
  transform: rotateY(-180deg);
  background-color: #e04030;
}
.card .top-corner {
  position: absolute;
  text-align: center;
  top: 0.5rem;
  left: 0.5rem;
  width: 3rem;
  height: 90%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
}

.cards {
  min-height: 12.4rem;
  min-width: 8.4rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.landingImage {
  z-index: 0;
}

.landingText {
  z-index: 1;
  position: relative;
}

.hand-total {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2em;
  background: rgb(239, 240, 252);
  border-radius: 50%;
  text-align: center;
  color: rgb(12, 36, 48);
}

.hand-result svg {
  width: 15rem;
}

@media (max-width: 640px) {
  .hand-total {
    width: 1.5rem;
    font-size: 0.8rem;
    height: 1.5rem;
  }
  .cards {
    min-height: 8rem;
    min-width: 8.4rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  .dealer-side {
    min-height: 8rem;
  }
  .hand-result svg {
    width: 11rem;
  }
}

.bust {
  background: #e04030;
}
.twenty-one {
  background: #e1ae0f;
}
.pop-enter-active {
  transition: all 0.3s ease-out;
}
.pop-leave-active {
  transition: all 0.1s ease-in;
}
.pop-enter,
.pop-leave-to {
  transform: scale(0) rotate(360deg);
}
.balloon-enter-active {
  animation: balloon-in ease-in-out 0.6s;
  transform-origin: 50% 100%;
}
.balloon-leave-active {
  transition: all 0.1s;
  transform-origin: 50% 100%;
}
.balloon-leave-to {
  transform: scale(0);
  opacity: 0;
}
@keyframes balloon-in {
  0% {
    transform: scale(0) skewX(0deg) rotate(-20deg);
  }
  40% {
    transform: scale(1) skewX(-5deg) rotate(10deg);
  }
  50% {
    transform: skewX(5deg) rotate(-5deg);
  }
  60% {
    transform: skewX(-4deg) rotate(3deg);
  }
  70% {
    transform: skewX(4deg) rotate(-1deg);
  }
  80% {
    transform: skewX(-3deg) rotate(0deg);
  }
  85% {
    transform: skewX(3deg);
  }
  90% {
    transform: skewX(-2deg);
  }
  95% {
    transform: skewX(2deg);
  }
  100% {
    transform: skewX(0deg);
  }
}

.hand-bet {
  position: relative;
  margin-top: 20px;
  left: 0;
  width: 100%;
  text-align: center;
}
.hand-bet .hand-coin {
  margin: 0.1rem;
  transition: all 0.3s ease-in;
}
.coin-leave-to,
.is-win .coin-enter {
  transform: translateY(-50vh);
  opacity: 0;
}
.coin-enter,
.is-win .coin-leave-to {
  transform: translateY(50vh);
  opacity: 0;
}

@keyframes turn {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(2160deg);
  }
}

#result-blackjack-moon {
  animation: moonrise 1s ease;
  transform: scale(100%);
  transform-origin: center;
  transform-box: fill-box;
}
@keyframes moonrise {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(100%);
  }
}

/* BlackJack */

/* plinko loader */

.plinko-loader {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 32px 0 #fff, -32px 0 #fff;
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: #fff2;
    box-shadow: 20px 0 #fff2, -20px 0 #fff;
  }
  50% {
    background-color: #fff;
    box-shadow: 20px 0 #fff2, -20px 0 #fff2;
  }
  100% {
    background-color: #fff2;
    box-shadow: 20px 0 #fff, -20px 0 #fff2;
  }
}

@media (min-width: 1024px) {
  .plinko-loader {
    width: 18px;
    height: 18px;
  }

  @keyframes flash {
    0% {
      background-color: #fff2;
      box-shadow: 32px 0 #fff2, -32px 0 #fff;
    }
    50% {
      background-color: #fff;
      box-shadow: 32px 0 #fff2, -32px 0 #fff2;
    }
    100% {
      background-color: #fff2;
      box-shadow: 32px 0 #fff, -32px 0 #fff2;
    }
  }
}
/* plinko loader */

/* coin flip for dolar btcsv */

.coin-wagered {
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .coin-wagered {
    width: 25px;
    height: 25px;
  }
}

.input-coin-wagered {
  width: 25px;
  height: 25px;
}

.side-wagered {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: transform 0.6s;
}

.front-wagered {
  transform: rotateY(0deg);
}

.back-wagered {
  transform: rotateY(180deg);
}

.flipping .front-wagered {
  transform: rotateY(180deg);
}

.flipping .back-wagered {
  transform: rotateY(360deg);
}

/* coin flip for dolar btcsv */

.homepage-gradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    radial-gradient(
      65.53% 415.43% at 111.92% 3.44%,
      rgba(0, 12, 74, 0.5) 0%,
      rgba(11, 89, 42, 0) 100%
    ),
    radial-gradient(
      86.92% 548.74% at -30.85% 117.37%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.1) 36.47%,
      rgba(255, 255, 255, 0.5) 46.35%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
}

/* smoke effect for plinko */

.steam-smoke {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
}

/* @keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

#circle-svg {
  display: none;
}

/* smoke effect for plinko */

/* slot game */

#slot-viewport {
  overflow: hidden;
  width: 100%;
  height: 750px;
  display: block;
}

#slot-viewport.mobile {
  position: absolute;
  top: 0;
  left: 0;
}

#slot-viewport.tablet {
  position: absolute;
  top: 0;
  left: 0;
}

#slot-viewport.desktop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#slot-content {
  position: absolute;
  width: 550px;
  height: 430px;
  left: 50%;
  top: 100px;
  margin-left: -275px;
}

.slot-slots {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}
@media (min-width: 640px) and (max-width: 1024px) {
  .sidebar {
    display: contents;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.9);
  }
}

/* slot game */

/* Box Animation */
.square-clone {
  position: absolute;
  width: 60px;
  height: 50px;
  background-color: #f7941d;
  z-index: 1000;
  animation: clone-diagonal-to-bottom-right 0.8s forwards;
}

@keyframes clone-diagonal-to-bottom-right {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
  }
}
/*poker*/

#poker-card:first-child {
  @apply ml-0;
}

#poker-card:last-child {
  @apply mr-0;
}

#card-front {
  backface-visibility: 'hidden';
  -webkit-backface-visibility: 'hidden';
  -moz-backface-visibility: 'hidden';
  z-index: 2;
}

#card-front > span:nth-of-type(1) {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}
#card-front > span:nth-of-type(2) {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  transform: rotate(180deg);
}
svg[class*='card-suit'] {
  --w: calc(8rem * 0.35);
  position: absolute;
  width: auto;
  height: var(--w);
  top: calc(50% - (var(--w) / 2));
  right: calc(50% - (var(--w) / 2));
  pointer-events: none;
}
svg[class*='card-face'] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

#card-back > svg {
  color: black;
  width: 100%;
  height: 100%;
}

.car-draw:hover {
  transform: rotateY(180deg) scale(1.02);
}

.card-front-side {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.card-back-side {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  /* background-color: radial-gradient(
    circle at 50% 30%,
    hsl(213, 80%, 37%) 0%,
    hsl(212, 85%, 23%) 100%
  ); */
  background-color: red;
}

/*poker*/

/* smoke animation */
.steam-cloud {
  fill: none;
  stroke: #f7f7f7;
  stroke-width: 42;
  stroke-linecap: round;
}

.steam-flow > svg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  filter: blur(5px);
  overflow: visible;
}

.cloud-1 {
  stroke: white;
  stroke-dasharray: 284;
  stroke-dashoffset: 0;
}
.cloud-2 {
  stroke: white;
  stroke-dasharray: 284;
  stroke-dashoffset: 0;
}
.cloud-3 {
  stroke: white;
  stroke-dasharray: 284;
  stroke-dashoffset: 0;
}
/* smoke animation */

@media screen and (min-width: 1294px) and (max-width: 1366px) {
  .Twidth {
    width: 100% !important;
  }
}

/* @media screen and (width: 375px) {
  .sm-margin {
    margin-top: 85vh;
  }
} */
.margin-top {
  margin-top: -50vh;
}

.twetchPayFrame {
  z-index: 100000;
}

@media (max-width: 380px) {
  .responsive-text {
    font-size: 0.75rem; /* Corresponds to Tailwind's text-xs */
  }
}

@media (min-width: 381px) {
  .responsive-text {
    font-size: 0.875rem; /* Corresponds to Tailwind's text-sm */
  }
}
@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 900px) {
  .land {
    margin-top: 25rem !important;
  }
}

#payout:focus {
  border: 2px solid #26272b !important;
}

.bg-gradient-home {
  background-image: linear-gradient(
    40deg,
    hsl(137deg 41% 9%) 71%,
    hsl(122deg 42% 23%) 92%,
    hsl(100deg 53% 35%) 98%,
    hsl(92deg 59% 37%) 100%,
    hsl(96deg 55% 31%) 101%,
    hsl(99deg 51% 25%) 102%,
    hsl(111deg 43% 25%) 102%,
    hsl(124deg 41% 24%) 101%,
    hsl(119deg 40% 30%) 101%,
    hsl(98deg 55% 38%) 100%,
    hsl(81deg 77% 43%) 100%
  );
  background-size: cover; /* To make sure the image covers the entire div */
  background-position: center; /* To center the image */
  background-repeat: no-repeat;
}

/* fireworks */
@keyframes firework {
  0% {
    transform: translate(var(--x), var(--initialY));
    width: var(--initialSize);
    opacity: 1;
  }
  50% {
    width: 0.5vmin;
    opacity: 1;
  }
  100% {
    width: var(--finalSize);
    opacity: 0;
  }
}

.firework,
.firework::before,
.firework::after {
  --initialSize: 0.5vmin;
  --finalSize: 45vmin;
  --particleSize: 0.2vmin;
  --color1: yellow;
  --color2: khaki;
  --color3: white;
  --color4: lime;
  --color5: gold;
  --color6: mediumseagreen;
  --y: -30vmin;
  --x: -50%;
  --initialY: 60vmin;
  content: '';
  animation: firework 2s infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, var(--y));
  width: var(--initialSize);
  aspect-ratio: 1;
  background: radial-gradient(
        circle,
        var(--color1) var(--particleSize),
        #0000 0
      )
      50% 0%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 50%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 50% 100%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 0% 50%,
    /* bottom right */
      radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 80%
      90%,
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 95% 90%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 90% 70%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 60%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 55% 80%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 70% 77%,
    /* bottom left */
      radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 22%
      90%,
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 45% 90%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 70%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 10% 60%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 31% 80%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 28% 77%,
    radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 13% 72%,
    /* top left */
      radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 80%
      10%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 95% 14%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 90% 23%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 100% 43%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 85% 27%,
    radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 77% 37%,
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 60% 7%,
    /* top right */
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 22%
      14%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 45% 20%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 34%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 10% 29%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 31% 37%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 28% 7%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 13% 42%;
  background-size: var(--initialSize) var(--initialSize);
  background-repeat: no-repeat;
}

.firework::before {
  --x: -50%;
  --y: -50%;
  --initialY: -50%;
  /*   transform: translate(-20vmin, -2vmin) rotate(40deg) scale(1.3) rotateY(40deg); */
  transform: translate(-50%, -50%) rotate(40deg) scale(1.3) rotateY(40deg);
  /*   animation: fireworkPseudo 2s infinite; */
}

.firework::after {
  --x: -50%;
  --y: -50%;
  --initialY: -50%;
  /*   transform: translate(44vmin, -50%) rotate(170deg) scale(1.15) rotateY(-30deg); */
  transform: translate(-50%, -50%) rotate(170deg) scale(1.15) rotateY(-30deg);
  /*   animation: fireworkPseudo 2s infinite; */
}

.firework-second {
  --x: 30vmin;
}

.firework-second,
.firework-second::before,
.firework-second::after {
  --color1: pink;
  --color2: violet;
  --color3: fuchsia;
  --color4: orchid;
  --color5: plum;
  --color6: lavender;
  --finalSize: 40vmin;
  left: 30%;
  top: 60%;
  animation-delay: -2s;
  animation: firework 2s infinite cubic-bezier(0.645, 0.045, 0.355, 1);
}

.firework-third {
  --x: -50%;
  --y: -50%;
}

.firework-third,
.firework-third::before,
.firework-third::after {
  --color1: cyan;
  --color2: lightcyan;
  --color3: lightblue;
  --color4: PaleTurquoise;
  --color5: SkyBlue;
  --color6: lavender;
  --finalSize: 35vmin;
  left: 50%;
  top: 30%;
  animation-delay: -1s;
  animation: firework 2s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
}

.firework-fourth {
  --x: -30vmin;
  --y: -50vmin;
}

.firework-fourth,
.firework-fourth::before,
.firework-fourth::after {
  --color1: rgb(255, 0, 34);
  --color2: rgb(18, 146, 146);
  --color3: rgb(175, 230, 173);
  --color4: rgb(238, 223, 175);
  --color5: rgb(235, 135, 182);
  --color6: rgb(230, 250, 234);
  --finalSize: 35vmin;
  left: 70%;
  top: 60%;
  animation-delay: -3s;
  animation: firework 2s infinite ease;
}
/* fireworks */

/* sand-clock */
.small {
  font-size: 0.5em;
}

.sand-clock {
  width: 2.5em;
  height: 3.5em;
  border-style: solid;
  border-width: 1.8em 0.8em;
  border-color: black transparent;
  border-radius: 15%;
  position: relative;
  animation: sandSpin 5s linear infinite;
}

.sand-clock:before {
  content: '';
  width: 1.8em;
  height: 3.2em;
  border-style: solid;
  border-width: 1.6em 0.7em;
  border-color: white transparent transparent;
  border-radius: 15%;
  position: absolute;
  top: -1.6em;
  left: -0.3em;
  animation: slideOut 5s linear infinite;
}

.sand-clock:after {
  content: '';
  width: 1.8em;
  height: 3.2em;
  border-style: solid;
  border-width: 0em 0.7em;
  border-color: transparent transparent white;
  border-radius: 15%;
  position: absolute;
  top: -1.6em;
  left: -0.3em;
  animation: slideIn 5s linear infinite;
}

@keyframes sandSpin {
  0% {
    transform: rotation(0deg);
  }
  90% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes slideOut {
  0% {
    top: -1.6em;
    width: 1.8em;
    border-width: 1.6em 0.7em;
  }
  90% {
    top: 0em;
    width: 0;
    border-width: 0em 0em;
    left: 0.35em;
  }
  100% {
    top: 0em;
    width: 0;
    border-width: 0em 0em;
    left: 0.35em;
  }
}

@keyframes slideIn {
  0% {
    border-width: 0em 0.7em;
  }
  90% {
    border-width: 1.6em 0.7em;
  }
  100% {
    border-width: 1.6em 0.7em;
  }
}

/* sand-clock */

.reflect-button {
  color: #050801;
  -webkit-box-reflect: below 10px
    linear-gradient(transparent, transparent, #0005);
}

/* prose customize */
.prose {
  @apply max-w-7xl;
}

.prose pre {
  @apply border border-gray-200 text-white bg-transparent;
}

.prose strong {
  @apply text-white;
}

.prose a {
  @apply text-yellow-500;
}

.prose p {
  @apply text-gray-400;
}

.prose h4,
.prose h4 strong,
.prose h3,
.prose h3 strong,
.prose h2,
.prose h2 strong,
.prose h1,
.prose h1 strong {
  @apply text-yellow-400;
}

.prose code {
  @apply px-1 py-0.5;
}

.prose img {
  /* Don't apply styles to next/image */
  @apply m-0;
}

.prose > :first-child {
  /* Override removing top margin, causing layout shift */
  margin-top: 1.25em !important;
  margin-bottom: 1.25em !important;
}

code[class*='language-'],
code,
pre[class*='language-'] {
  color: #fff !important;
}

cite {
  display: none !important;
}

/* prose customize */

.Hand .Playing-card {
  position: absolute;
}

.Playing-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 4px;
  -webkit-backface-visibility: hidden; /*fix for jumping*/
}

.neon-text-green {
  color: #fff;
  text-shadow: 0 0 7px #0fa, 0 0 10px #0fa, 0 0 21px #0fa, 0 0 42px #0fa,
    0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
  text-align: center;
  text-transform: uppercase;
}

.neon-text-red {
  color: #fff;
  text-shadow: 0 0 7px #a52a2a, 0 0 10px #a52a2a, 0 0 21px #a52a2a,
    0 0 42px #a52a2a, 0 0 82px #a52a2a, 0 0 92px #a52a2a, 0 0 102px #a52a2a,
    0 0 151px #a52a2a;
  text-align: center;
  text-transform: uppercase;
}

.neon-text-bj {
  color: #fff;
  text-shadow: 0 0 7px #ffbf00, 0 0 10px #ffbf00, 0 0 21px #ffbf00,
    0 0 42px #ffbf00, 0 0 82px #ffbf00, 0 0 92px #ffbf00, 0 0 102px #ffbf00,
    0 0 151px #ffbf00;
  text-align: center;
  text-transform: uppercase;
}

.neon-text-gray {
  color: #fff;
  text-shadow: 0 0 7px #a9a9a9, 0 0 10px #a9a9a9, 0 0 21px #a9a9a9,
    0 0 42px #a9a9a9, 0 0 82px #a9a9a9, 0 0 92px #a9a9a9, 0 0 102px #a9a9a9,
    0 0 151px #a9a9a9;
  text-align: center;
  text-transform: uppercase;
}

/* Neon Text */

/* texas-poker */

.poker-container {
  position: relative;
  transition: left 0.2s;
  opacity: 1;
  left: 0;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.5);
}
.menu {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  width: 320px;
  right: -320px;
  transition: right 0.2s;
}
.active-menu .app {
  left: -320px;
  opacity: 0.4;
}
.active-menu .menu {
  right: 0px;
}
.games-drawer {
  position: absolute;
  width: 320px;
  top: 0;
  bottom: 0;
  left: -320px;
  padding-top: 66px;
}
.games-drawer .toggle-games {
  position: absolute;
  cursor: pointer;
  right: -25px;
  top: calc(50% - 50px);
}
.toggle-games .chevron {
  transform: scale(1, 1) translate(0, 0);
  transform-origin: center;
}
[data-games='visible'] .games-drawer {
  left: 0;
}
[data-games='visible'] .toggle-games .chevron {
  transform: scale(-1, 1) translate(13px, 0);
}
.poker-bg {
  background-image: linear-gradient(90deg, #169651, #17a95a 70%, #169651);
}

.table {
  height: 100vh;
}

.table .leave-table {
  position: absolute;
  top: 0;
  width: 24px;
  height: 24px;
  margin: 20px;
  right: 44px;
  cursor: pointer;
}

.table .leave-table svg {
  fill: white;
}

.room {
  height: 100vh;
  display: grid;
  align-content: center;
  justify-content: center;
  grid-template-areas: 'body';
}

.room .table {
  fill: #08d362;
  grid-area: body;
  width: 80vw;
  max-width: 820px;
  height: auto;
  overflow: visible;
}

.actions.active {
  opacity: 1;
}
.actions button[disabled] {
  background-color: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.3);
}
.actions .bet-control.disabled {
  visibility: hidden;
}

.actions .bet-control .bet-selector {
  display: grid;
  grid-template-columns: 1fr 7fr 1fr;
}

.stacks .stack {
  position: relative;
  display: inline-block;
}

.stacks .stack .chip:nth-child(2) {
  position: absolute;
  top: -0.25vmin;
  left: 0;
}
.stacks .stack .chip:nth-child(3) {
  position: absolute;
  top: -0.5vmin;
  left: 0;
}
.stacks .stack .chip:nth-child(4) {
  position: absolute;
  top: -0.75vmin;
  left: 0;
}
.stacks .stack .chip:nth-child(5) {
  position: absolute;
  top: -0.1vmin;
  left: 0;
}
.player-progress {
  position: relative;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.3);
  height: 0.5vmin;
}
.player-progress .bar {
  transition-property: width;
  transition-duration: 20s;
  transition-timing-function: linear;
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.player-chips {
  position: absolute;
}

#texas-holdem-table .ag-header-row,
#texas-holdem-table .ag-picker-field-wrapper {
  background-color: #053d1f !important;
}

@keyframes dealCard {
  0% {
    transform: translate(-50%, -150%) scale(0);
  }
  100% {
    transform: translate(0%, 0%) scale(1);
  }
}

.card-animation {
  animation: dealCard 0.5s ease forwards;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}
